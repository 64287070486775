export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const cartStore = useCartStore();

  if (!authStore.isAuthenticated && !authStore.isGuest) {
    return navigateTo({
      name: 'login',
      query: { redirect: to.path }
    });
  }

  if (
    to.name === 'checkout-payment' &&
    cartStore.takeaway.phone &&
    !cartStore.takeaway.isPhoneValid
  ) {
    return navigateTo({ name: 'checkout-takeaway' });
  }
});
